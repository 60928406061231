<template>
  <div id="app">
    <!-- Loader -->
    <div id="preloader" v-if="loading">
      <div id="status" class="contspinner clearfix">
        <!-- <div class="spinner"></div>-->
        <img src="@/assets/images/logo_spinner.png"> 
        <div class="spinner-border float-center" style="width: 4rem; height: 4rem; color: rgb(17, 82, 147);" role="status">
            <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <!-- <div class="text-center">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div> -->
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true
    };
  },
/*   created() {
    setTimeout(() => (this.loading = false), 2000);
  } */
  mounted() {
    setTimeout(() => (this.loading = false), 3000);
    console.log('App Mounted!!!')
  }
};
</script>
<style>
    .contspinner {
        /* display: flex;
        align-items: center;
        justify-content: center; 
        background: #ddd;*/
        width: 4rem; 
        height: 4rem;
    }
    .contspinner img {
        position: absolute;
        width: 42px; 
        height: 42px; 
        left: calc(50% - 19px);
        top: calc(50% - 4px);
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
    }
</style>